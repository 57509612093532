import { lazy } from 'react';

const routes=[
    {
        path: '/',
        name: 'Home',
        exact: true,
        page: '@/pages/home',
        component: lazy(() => import(/* webpackChunkName:'homePage' */ './home')),
      },
      {
        path: '/about',
        name: 'About',
        exact: true,
        page: '@/pages/about',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './about')),
      },
      {
        path: '/wallet',
        name: 'Wallet',
        exact: true,
        page: '@/pages/wallet',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './wallet')),
      },
      {
        path: '/wallet/:type',
        name: 'Wallet',
        exact: true,
        page: '@/pages/wallet',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './wallet')),
      },
      {
        path: '/drawing',
        name: 'Drawing',
        exact: true,
        page: '@/pages/drawing',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './drawing')),
      },
      {
        path: '/drawing/detail/:id',
        name: 'DrawingDetail',
        exact: true,
        page: '@/pages/drawing/detail',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './drawing/detail')),
      },
      {
        path: '/mine',
        name: 'Mine',
        exact: true,
        page: '@/pages/mine',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './mine')),
      },
      {
        path: '/mine/task',
        name: 'Task',
        exact: true,
        page: '@/pages/mine/task',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './mine/task')),
      },
      {
        path: '/mine/subhistory',
        name: 'SubscriptionHistory',
        exact: true,
        page: '@/pages/mine/subhistory',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './mine/subhistory')),
      },
      {
        path: '/mine/subhistory/detail/:id',
        name: 'SubscriptionHistoryDetail',
        exact: true,
        page: '@/pages/mine/subhistory/detail',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './mine/subhistory/detail')),
      },
      {
        path: '/mine/transhistory',
        name: 'TransactionHistory',
        exact: true,
        page: '@/pages/mine/transhistory',
        component: lazy(() => import(/* webpackChunkName:'aboutPage' */ './mine/transhistory')),
      },



]


export default routes;
import { createContext, useContext, useEffect, useState } from "react";
import { getUserAssets, getUserInfo, login } from "../service/common";
import { AssetsInfo, UserInfo } from "../service/interface";
import { LoadingOutlined } from "@ant-design/icons";

interface AccountProviderProps {
  userInfo: UserInfo;
  userAssets: AssetsInfo[];
}
const defaultValue: AccountProviderProps = {
  userInfo: {} as UserInfo,
  userAssets: [],
};

export const AccountContext = createContext(defaultValue);

export const AccountProvider = ({ children }: any) => {
  const [userInfo, setUserInfo] = useState<UserInfo>({} as UserInfo);
  const [userAssets, setUserAssets] = useState<AssetsInfo[]>(
    [] as AssetsInfo[]
  );
  const getUserAssetsFromServer = async () => {
    let res: any = await getUserAssets();
    if (res) {
      setUserAssets(res);
    }
    return !!res;
  };
  const getUserInfoFromServer = async () => {
    let res = await getUserInfo();
    if (res) {
      setUserInfo(res);
      await getUserAssetsFromServer();
    }
    return !!res;
  };
  useEffect(() => {
    const init = async () => {
      let res = await getUserInfoFromServer();
      if (!res) {
        let sid = await login();
        if (sid) {
          await getUserInfoFromServer();
        }
      }
    };
    init();
  }, []);
  return (
    <AccountContext.Provider value={{ userInfo, userAssets }}>
      {userInfo ? (
        children
      ) : (
        <div className="flex justify-center items-center h-screen">
          <LoadingOutlined />
        </div>
      )}
    </AccountContext.Provider>
  );
};

export function useAccount() {
  return useContext(AccountContext);
}

import { postHttpData } from "../common/utils/axiosData";
import Cookies from "js-cookie";


/**
 * telegram授权登录
 * @returns 
 */
export const login = async () => {
  let res = "";
  try {
    let rs = await postHttpData("/", "tg_login", { appdata: window['location'].hash.replace('##tgWebAppData=', '') }, {});
    res = rs?.result?.sid || '';
    if (res) {
      Cookies.set('sid', res);
      console.log('login success')
    }
  } catch (e) {
    console.log(e);
  }
  return res;
};

/**
 * 查询用户信息
 * @returns 
 */
export const getUserInfo = async () => {
  let res = null;
  try {
    let rs = await postHttpData("/", "user_info", {}, {});
    res = rs.result;
  } catch (e) {
    console.log(e);
  }
  return res;
};


/**
 * 查询用户资产
 * @returns 
 */
export const getUserAssets = async () => {
  let res = {};
  try {
    let rs = await postHttpData("/", "user_assets", {}, {});
    res = rs.result;
  } catch (e) {
    console.log(e);
  }
  return res;
};

/**
 * 查询用户资产流水
 * @param state # 状态(可选 1: 进行中 2: 待开奖 3: 已结束 默认0查询所有)
 * @param page # 起始页(可选 默认为1)
 * @param pagesize # 每页条数(可选 默认为2)
 * @returns 
 */
export const getAssetsLog = async (state: any, page: any, pagesize: any) => {
  let res = {};
  try {
    let rs = await postHttpData("/", "assets_log", {state,page,pagesize}, {});
    res = rs.result;
  } catch (e) {
    console.log(e);
  }
  return res;
};
import axios, { AxiosRequestConfig } from  'axios';
import Cookies from 'js-cookie';
const baseUrl = process.env.REACT_APP_API_URL ;
const walletUrl = process.env.REACT_APP_WALLET_URL;

export async function postHttpData(path: string, method: string, postData?: any, options?: AxiosRequestConfig) {
    const timeout = 10000;
    options = options || {};
    postData = postData || {};
    try {
      options.timeout = timeout;
      options.withCredentials = true;
      options.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": Cookies.get('sid')
      };
      const body = {
        jsonrpc: "2.0",
        method: method,
        id: Date.now(),
        params: postData
      };
      const res = await axios.post(baseUrl + path, body,options)
      if (res.status === 200) {
        return res.data;
      } else {
        console.log(`${path} ${res.statusText} `, res);
      }
      return res.data
    } catch (error: any) {
      console.log('### FETCH ERROR:');
      console.log('### FETCH URL:', baseUrl + path, postData)
      console.log(error.code)
      console.log(error.message)
      if (error.response) {
        console.log(error.response.status);
        console.log(error.response.data);
      }
      return {};
    }
  }

  export async function postWalletHttpData(path: string, method: string, postData?: any, options?: AxiosRequestConfig) {
    const timeout = 10000;
    options = options || {};
    postData = postData || {};
    try {
      options.timeout = timeout;
      options.withCredentials = true;
      options.headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization": Cookies.get('sid')
      };
      const body = {
        jsonrpc: "2.0",
        method: method,
        id: Date.now(),
        params: postData
      };
      const res = await axios.post(walletUrl + path, body,options)
      if (res.status === 200) {
        return res.data;
      } else {
        console.log(`${path} ${res.statusText} `, res);
      }
      return res.data
    } catch (error: any) {
      console.log('### FETCH ERROR:');
      console.log('### FETCH URL:', baseUrl + path, postData)
      console.log(error.code)
      console.log(error.message)
      if (error.response) {
        console.log(error.response.status);
        console.log(error.response.data);
      }
      return {};
    }
  }







import { Image, Modal as ModalAnt } from "antd";
import styles from "./Modal.module.scss";
import { ReactNode } from "react";

interface ModalProps {
  open: boolean;
  title?: ReactNode | string;
  showCancel?: boolean;
  showConfirm?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
  cancelText?: ReactNode | string;
  confirmText?: ReactNode | string;
  children: ReactNode;
}
export const Modal = (props: ModalProps) => {
  return (
    <ModalAnt
      className={styles["modal"]}
      open={props.open}
      centered={true}
      closable={false}
      footer={null}
      width={450}
    >
      <div className={styles["modal-no-title-header"]}></div>
      <div className={styles["modal-header"]}>
        <div className={styles["modal-title"]}>
          {props.title}
          <div className={styles["modal-close-wrap"]}>
            <Image
              src="/statics/images/close.png"
              className={styles["modal-close"]}
              onClick={props.onClose}
              preview={false}
            />
          </div>
        </div>
      </div>
      <div className={styles["modal-content"]}>{props.children}</div>
      <div className={styles["modal-footer"]}>
        {props.showCancel && (
          <div className={styles["modal-cancel"]} onClick={props.onCancel}>
            {props.cancelText || "CANCEL"}
          </div>
        )}
        {props.showConfirm && (
          <div
            className={`${styles["modal-confirm"]} ${
              !props.showCancel && styles["modal-confirm-only"]
            }`}
            onClick={props.onConfirm}
          >
            {props.confirmText || "CONFIRM"}
          </div>
        )}
      </div>
    </ModalAnt>
  );
};

import React, { Suspense, lazy } from "react";
// import logo from './logo.svg';
import "./App.scss";
import { Routes, Route } from "react-router-dom";
import routes from "./pages";
import { AccountProvider } from "./context/AccountContext";
import { Header } from "./components/Header";
import { ConfigProvider, theme } from "antd";
import { CommonComponentProvider } from "./context/CommonContext";

function App() {
  return (
    <AccountProvider>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm, // 使用暗黑主题算法
          token: {
            fontFamily: `D-DIN-PRO,'ArialMT', 'Arial', sans-serif`,
          },
        }}
      >
        <CommonComponentProvider>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {routes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
            </Routes>
          </Suspense>
        </CommonComponentProvider>
      </ConfigProvider>
    </AccountProvider>
  );
}

export default App;

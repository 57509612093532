import {
  ContextType,
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { Modal } from "../components/Modal";

interface ConfirmProps {
  title?: ReactNode | string;
  content: ReactNode | string;
  confirmText?: ReactNode | string;
  cancelText?: ReactNode | string;
  showCancel?: boolean;
  showConfirm?: boolean;
  closeable?: boolean;
  centered?: boolean;
  width?: number;
  onConfirm?: (callbak?: any) => void;
  autoClose?: boolean;
  onCancel?: (isTopClose?: boolean) => void;
  onClose?: () => void;
}
const initContext = {
  modal: ({
    content,
    title,
    confirmText,
    cancelText,
    width,
    onConfirm,
    onCancel,
  }: ConfirmProps) => {
    // todo
    return "";
  },
};
const CommonComponentContext = createContext<any>(initContext);

function ModalContainer({ handler }: { handler: any }) {
  const [modals, setModals] = useState<{ key: string; props: ConfirmProps }[]>(
    []
  );
  const selfRef = useRef<{ modals: { key: string; props: ConfirmProps }[] }>({
    modals: [],
  });
  const self = selfRef.current;
  handler.modal = useCallback(
    (params: ConfirmProps) => {
      params.autoClose = params.autoClose || false;
      params.showConfirm = params.showConfirm || true;
      const key = "Modal_" + Math.random();
      const closeConfirm = () => {
        self.modals = self.modals.filter((v) => v.key !== key);
        setModals(self.modals.map((v) => v));
      };
      self.modals.push({
        key,
        props: {
          ...params,
          onClose: () => {
            closeConfirm();
          },
          onCancel: (isTopClose?: boolean) => {
            if (params.onCancel) params.onCancel(isTopClose);
            closeConfirm();
          },
          onConfirm: () => {
            if (params.onConfirm) {
              params.onConfirm(() => {
                closeConfirm();
              });
              if (params.autoClose) closeConfirm();
              return;
            }
            closeConfirm();
          },
        },
      });
      setModals(self.modals.map((v) => v));
      return key;
    },
    [modals]
  );

  return (
    <div>
      {modals.map(({ key, props }) => (
        <Modal open={true} key={key} onCancel={handler.closeConfirm} {...props}>
          {props.content}
        </Modal>
      ))}
    </div>
  );
}
export function CommonComponentProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const selfRef = useRef<any>(initContext);
  const self = selfRef.current;

  return (
    <CommonComponentContext.Provider value={self}>
      {children}
      <ModalContainer handler={self} />
    </CommonComponentContext.Provider>
  );
}

export function useCommon() {
  const ctx = useContext(CommonComponentContext);
  return ctx;
}
